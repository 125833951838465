<template>
  <div>
    <label for="payment-method">Método de {{ type === 'bill_to_receive' ? 'recebimento' : 'pagamento' }}</label>
    <multiselect
        id="payment-method"
        track-by="id"
        label="name"
        v-model="payment.payment_method"
        :options="paymentMethods"
        :option-height="40"
        :showLabels="false"
        :searchable="false"
        :allowEmpty="false"
        :showNoResults="false"
        placeholder="Selecionar..."
        class="with-border"
        :disabled="disabled || isPatientCreditMethod"
    >
        <template slot="caret">
            <div class="chevron">
                <ChevronDown />
            </div>
        </template>
        <template slot="option" slot-scope="{ option }">
            <div class="method-name">
                {{ option.name }}
                <!-- <Icon :tooltip="option.anticipation ? 'Antecipação ativa' : 'Antecipação inativa'">
                    <AnticipationIcon class="icon" :class="{ inactive: !option.anticipation }"" />
                </Icon> -->
            </div>
            <div v-if="option.type === 'card'">
                <div class="card-wrapper">
                    <div class="card-machine">{{ option.card_machine?.name }}</div>
                    <div v-if="option.card_flag" class="card-flag">({{ option.card_flag?.name }})</div>
                </div>
            </div>
        </template>
        <template slot="noOptions"> Nenhuma opção</template>
    </multiselect>
    <div v-if="validated && payment.payment_method === null" class="custom-invalid-feedback">Campo obrigatório</div>
  </div>
</template>
<script>
export default {
    props: {
        payment: Object,
        type: String,
        isRecurrence: Boolean,
        paymentMethods: Array,
        validated: Boolean
    },
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'), 
        // AnticipationIcon: () => import('@/assets/icons/procedure.svg'),
        // Icon: () => import('@/components/General/Icon')
    },
    computed: {
        disabled() {
      return (
        !!this.payment.id &&
        (!this.isRecurrence ||
          this.payment.installments.some(
            installment => installment.status === 'paid_out'
          ))
      )
    },
    isPatientCreditMethod() {
      return this.payment.payment_method?.name === 'Crédito do paciente'
    }
  },
}
</script>
<style lang="scss" scoped>
.method-name {
    display: flex;
    align-items: end;
    gap: 3px;
    font-weight: 400;
    color: var(--type-active);
    .icon {
        width: 12px;
        height: 12px;        
        fill: var(--states-success-dark-green);

        &.inactive {
            fill: var(--type-active);
        }
    }
}
.card-wrapper {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 11px;
    .card-flag {
        color: var(--type-active);
    }
    .card-machine {
        color: var(--blue-500);
    }
}
</style>